<template>
<v-dialog
  v-model="dialog"
  :persistent="window > 0"
  max-width="500px"
  transition="dialog-transition"
>
  <template v-slot:activator="{ on }">
    <v-btn color="color3Text color3--text" v-on="on" fab small :class="btnClass">
      <v-icon>fas fa-medkit</v-icon>
    </v-btn>
  </template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>Forfeit?</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="color1 color1Text--text" small fab @click.stop="dialog = false">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-0">
      <v-alert type="warning" :value="warn" prominent>
        You can only forfeit/unforfeit teams in unlocked pools.
      </v-alert>
      <v-window v-model="window" touchless>
        <v-window-item :value="0">
          <v-card flat>
            <v-card-text class="pt-0">
              <div class="text-end">
                <v-btn
                  color="color3"
                  text x-small
                  v-if="undoTeams.length > 0"
                  @click.stop="undoTeam"
                >Undo a forfeit</v-btn>
              </div>
              <div class="subtitle-1">
                Select the team that is forfeiting
              </div>
              <v-autocomplete
                :items="teams"
                label="Forfeiting Team"
                v-model="selectedTeam"
                return-object
                item-text="name"
                color="color3"
              ></v-autocomplete>
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item :value="1">
          <v-card flat>
            <v-card-text>
              <div class="subtitle-1">
                Select the team to unforfeit
              </div>
              <v-autocomplete
                :items="undoTeams"
                label="Forfeiting Team"
                v-model="selectedTeam"
                return-object
                item-text="name"
                color="color3"
              ></v-autocomplete>
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item :value="2">
          <v-card flat>
            <v-card-text class="subtitle-1 font-weight-bold text-center">
              {{undo ? ' Undo ' : ''}}Forfeit{{undo ? ' For ' : ''}}<br>{{selectedTeam ? selectedTeam.name : ''}}?
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                color="success"
                @click.stop="forfeit"
                :loading="loading"
              >Yes</v-btn>
              <v-btn color="error" @click.stop="reset">no</v-btn>
            </v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['round', 'btnClass'],
  data () {
    return {
      dialog: false,
      window: 0,
      selectedTeam: null,
      loading: false,
      undo: false
    }
  },
  computed: {
    ...mapGetters(['getTeam']),
    warn () {
      return this.round.pools.filter(f => f.locked).length > 0
    },
    teams () {
      return this.round && this.round.unlockedPoolTeams.filter(f => !f.forfeit).map(m => {
        const t = this.getTeam(m.teamId)
        return {
          id: m.id,
          name: t.name,
          teamId: m.teamId,
          isPoolTeam: m.type === 'pool'
        }
      })
    },
    undoTeams () {
      return this.round && this.round.unlockedPoolTeams.filter(f => f.forfeit).map(m => {
        const t = this.getTeam(m.teamId)
        return {
          id: m.id,
          name: t.name,
          teamId: m.teamId,
          isPoolTeam: m.type === 'pool'
        }
      })
    }
  },
  methods: {
    reset () {
      this.window = 0
      this.undo = false
      this.selectedTeam = null
    },
    undoTeam () {
      this.undo = true
      this.window = 1
    },
    forfeit () {
      if (!this.selectedTeam) return
      this.loading = true
      this.$VBL.team.forfeit(this.selectedTeam, this.undo)
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.loading = false
          this.dialog = false
        })
    }
  },
  watch: {
    dialog: function (val) {
      this.reset()
    },
    selectedTeam: function (val) {
      this.window = val ? 2 : 0
    }
  }
}
</script>

<style>

</style>
